import { securedWrap } from '@mop/shared/utils/securedWrap';
import { localStorageGet, localStorageSet, localStorageRemove } from '@mop/shared/utils/localStorage';
import type { JobModel } from '@/types/jobModel';

type WishlistStorage = {
  wishlistItemsRef: Ref<JobModel[]>;
  wishlistRef: Ref<string>;
};
const STORAGE_KEY = 'wishlist';

export default function useMopWishlist() {
  const { $gtm } = useNuxtApp();
  const storage = initStorage<WishlistStorage>('useWishlist');
  const { jobListRef, fetchAllJobs } = useMopJob();

  const wishlistItemsRef: Ref<JobModel[]> =
    storage.get('wishlistItemsRef') ?? storage.saveAndGet('wishlistItemsRef', ref([]));

  const wishlistRef: Ref<string> = storage.get('wishlistRef') ?? storage.saveAndGet('wishlistRef', ref(''));

  async function init() {
    watch(jobListRef, assignJobItems);
    wishlistRef.value = getItems();
    if (wishlistRef.value.length) {
      await fetchAllJobs();
    }
  }

  function assignJobItems() {
    wishlistItemsRef.value = jobListRef.value?.filter((job: JobModel) => wishlistItemExists(job.getId())) || [];
  }

  function getItems() {
    return localStorageGet(STORAGE_KEY) ?? '';
  }

  function toggleWishlistItem(job: JobModel) {
    wishlistItemExists(job.getId()) ? removeItem(job) : addItem(job);
  }

  function addItem(job: JobModel) {
    const id: string = job.getId();
    const items: string[] = getItems().split('|');
    items.push(id);
    wishlistRef.value = items.join('|');

    localStorageSet(STORAGE_KEY, wishlistRef.value);
    $gtm.trackEvent({
      eventName: 'add_to_wishlist',
      customValue: {
        items: [job.getGtmTrackData()],
      },
    });
    assignJobItems();
  }

  function removeItem(job: JobModel) {
    const id: string = job.getId();
    const items: string[] = getItems()
      .split('|')
      .filter((item) => item !== id);
    if (items.length) {
      wishlistRef.value = items.join('|');
      localStorageSet(STORAGE_KEY, wishlistRef.value);
    } else {
      localStorageRemove(STORAGE_KEY);
      wishlistRef.value = '';
    }
    $gtm.trackEvent({
      eventName: 'remove_from_wishlist',
      customValue: {
        items: [job.getGtmTrackData()],
      },
    });
    assignJobItems();
  }

  function wishlistItemExists(id: string) {
    return wishlistRef.value.includes(id);
  }

  return securedWrap({
    init,
    wishlistItemExists,
    toggleWishlistItem,
    wishlistItemsRef,
  });
}
